<template>
  <b-card
    no-body
  >
    <div class="m-2">
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('ENTRIES') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            v-if="canEditGoal"
            variant="primary"
            @click="openGoalModel()"
          >
            <span>{{ $t('ADD_GOAL') }} </span>
            <feather-icon icon="PlusCircleIcon" />
          </b-button>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mt-1 mb-md-0"
        >
          <small
            v-if="content.id"
            class="text-muted"
          >
            <b-badge
              pill
              variant="light-primary"
              class="mr-1 text-capitalize small"
            >
              {{ content.contentType.name }}
            </b-badge>
            <b-badge
              pill
              variant="light-warning"
              class="mr-1 text-capitalize small"
            >
              {{ content.contentFrequency.name }}
            </b-badge>
            <b-badge
              pill
              variant="light-info"
              class="mr-1 text-capitalize small"
            >
              {{ content.contentStatus.name }}
            </b-badge>
            <b-badge
              pill
              variant="light-secondary"
              class="mr-1 text-capitalize small"
            >
              {{ formatDateTime(content.start) }}
            </b-badge>
            <b-badge
              pill
              variant="light-secondary"
              class="mr-1 text-capitalize small"
            >
              {{ formatDateTime(content.end) }}
            </b-badge>
          </small>
        </b-col>
      </b-row>
    </div>
    <b-table
      ref="refGoalsListTable"
      responsive
      :fields="tableColumns"
      :items="paginatedGoals"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      :sort-desc.sync="isSortDesc"
      class="position-relative"
    >
      <!-- Column: Description -->
      <template #cell(Description)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              :id="`goal-row-${data.item.id}`"
              size="32"
              :variant="data.item.enabled ? 'light-success' : 'light-warning'"
            >
              <feather-icon
                icon="TargetIcon"
              />
            </b-avatar>
          </template>
          <b-link
            class="font-weight-bold d-block text-nowrap"
            @click="openGoalModel(data.item)"
          >
            {{ strSubstring(data.item.description,15) }}
          </b-link>
        </b-media>
      </template>

      <!-- Column:Task & Subtask-->
      <template #cell(Source)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ strSubstring(data.item.task.name,10) }} ({{ strSubstring(data.item.subtask.name,10) }})
          </span>
        </b-media>
      </template>

      <!-- Column:Goal Type -->
      <template #cell(GoalType)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.goalType.name }}
          </span>
        </b-media>
      </template>

      <!-- Column:Goal Period -->
      <template #cell(GoalPeriod)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.goalPeriod.name }}
          </span>
        </b-media>
      </template>
      <!-- Column:Goal Subject -->
      <template #cell(GoalSubject)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.goalSubject.name }}
          </span>
        </b-media>
      </template>
      <!-- Column:Goal Value -->
      <template #cell(Value)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.value }}
          </span>
        </b-media>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            v-if="canEditGoal"
            :id="`goal-row-${data.item.ID}-edit-icon`"
            v-b-modal.goals-modal
            icon="EditIcon"
            class="cursor-pointer"
            size="16"
            @click="openGoalModel(data.item)"
          />

          <feather-icon
            :id="`goal-row-${data.item.ID}-preview-icon`"
            v-b-modal.goals-modal
            icon="AwardIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'content-view', params: { id: content.id }})"
          />

          <feather-icon
            v-if="canEditGoal"
            :id="`goal-row-${data.item.ID}-delete-icon`"
            icon="Trash2Icon"
            class="cursor-pointer mr-1"
            size="16"
            @click="confirmAndDeleteGoal(data.item)"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalGoals"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>
      </b-row>
    </div>
    <goal-model
      :fetch-goals="fetchAllGoals"
      :content="content"
      :edit-goal="editGoal"
      :all-goal-subjects="allGoalSubjects"
      :all-goal-periods="allGoalPeriods"
      :all-goal-types="allGoalTypes"
      :all-content-tasks="allContentTasks"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BLink,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BBadge,
  BPagination,
  BAvatar,
  VBPopover,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from 'vuex'
import constants from '@/constants/static.json'
import { mixinList } from '@/constants/mixinValidations'
import { mixinDate } from '@/constants/mixinDate'
import mixinAlert from '@/constants/mixinAlert'
import {
  resolveUserStatusVariant,
  updateBreadcrumb,
} from '@/constants/utils'
import useJwt from '@/auth/jwt/useJwt'
import router from '@/router'
import GoalModel from './GoalsModel.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BPagination,
    BAvatar,
    BLink,
    BBadge,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    VBPopover,
    GoalModel,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  mixins: [mixinList, mixinDate],
  data() {
    return {
      canEditGoal: true,
      content: useJwt.getContent() || {},
      userData: useJwt.getUser() || {},
      searchQuery: '',
      sortBy: 'id',
      isSortDesc: true,
      perPage: constants.PER_PAGE,
      perPageOptions: constants.PER_PAGE_OPTIONS,
      breadCrumb: constants.BREADCRUMB,
      currentPage: 1,
      editGoal: {
        id: 0,
        contentId: 0,
        taskId: '',
        subtaskId: '',
        description: '',
        goalTypeId: '',
        goalPeriodId: '',
        goalSubjectId: '',
      },
      tableColumns: [
        { key: 'Description', sortable: false },
        { key: 'Source', sortable: false },
        { key: 'GoalType', sortable: false },
        { key: 'GoalPeriod', sortable: false },
        { key: 'GoalSubject', sortable: false },
        { key: 'Value', sortable: false },
        { key: 'Actions' },
      ],
    }
  },
  computed: {
    ...mapState('task', ['allContentTasks']),
    ...mapState('goal', [
      'allGoalSubjects',
      'allGoalPeriods',
      'allGoalTypes',
      'allGoals']),
    totalGoals() {
      return this.allGoals.length
    },
    dataMeta() {
      const localItemsCount = this.filteredGoals.length
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalGoals,
      }
    },
    filteredGoals() {
      let data = this.allGoals || []
      // Filter by search query
      if (this.searchQuery) {
        const queryLowered = this.searchQuery.toLowerCase()
        data = data.filter(goal => (goal.description && goal.description.toLowerCase().includes(queryLowered)))
      }
      // Sort the data
      data = data.sort((a, b) => {
        const aValue = a[this.sortBy]
        const bValue = b[this.sortBy]

        // If sorting by ID, ensure numeric sorting
        if (this.sortBy === 'id') {
          return this.isSortDesc ? bValue - aValue : aValue - bValue
        }

        // Otherwise, sort alphabetically
        const aStr = aValue ? aValue.toString().toLowerCase() : ''
        const bStr = bValue ? bValue.toString().toLowerCase() : ''

        if (this.isSortDesc) {
          return aStr < bStr ? 1 : -1
        }
        return aStr > bStr ? 1 : -1
      })
      return data
    },
    paginatedGoals() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      return this.filteredGoals.slice(start, end)
    },
  },
  async created() {
    try {
      const routeId = parseInt(router.currentRoute.params.id, 10)
      if (routeId) {
        const response = await this.fetchContentByID(routeId)
        if (response && response.data) {
          this.content = response.data
          this.fetchAllGoals(this.content.id)
          this.fetchContentTaskByID(this.content.id)
          this.configureBreadcrumbs()
        }
      }
      this.fetchGoalType()
      this.fetchGoalPeriod()
      this.fetchGoalSubject()
    } catch (error) {
      this.errorMessage(this.$i18n.t('MESSAGE.PAGE_LOAD_FAILED'))
    }
  },
  setup() {
    const {
      successMessage,
      errorMessage,
    } = mixinAlert()

    return {
      avatarText,
      resolveUserStatusVariant,
      successMessage,
      errorMessage,
    }
  },
  methods: {
    ...mapActions('content', [
      'fetchContentByID']),
    ...mapActions('task', [
      'fetchContentTaskByID']),
    ...mapActions('goal', [
      'fetchAllGoals',
      'fetchGoalPeriod',
      'fetchGoalType',
      'fetchGoalSubject',
      'deleteGoal']),
    confirmAndDeleteGoal(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.description}  ${this.$i18n.t('GOAL')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.handleDeleteGoal(data)
          }
        })
    },
    handleDeleteGoal(data) {
      if (data) {
        this.deleteGoal(data.id).then(response => {
          if (response) {
            this.successMessage(this.$i18n.t('MESSAGE.GOAL_DELETED'))
            this.fetchAllGoals(this.content.id)
          }
        }).catch(() => {
          this.errorMessage(this.$i18n.t('MESSAGE.GOAL_EXIST'))
        })
      }
    },
    configureBreadcrumbs() {
      const company = useJwt.getBreadcrumb(this.breadCrumb.COMPANY)
      const project = useJwt.getBreadcrumb(this.breadCrumb.PROJECT)
      const content = useJwt.getBreadcrumb(this.breadCrumb.CONTENT)
      const breadcrumbs = [
        { text: company.name, to: `/companies/company-view/${company.id}`, active: false },
        { text: project.name, to: `/project/project-view/${project.id}`, active: false },
        { text: content.name, to: `/content/content-view/${content.id}`, active: false },
        { text: this.$i18n.t('Goals'), active: true },
      ]
      updateBreadcrumb(this, breadcrumbs)
    },
    openGoalModel(data) {
      if (data) {
        this.editGoal = { ...data }
      }
      if (this.canEditGoal) {
        this.$bvModal.show('goal-modal')
      }
    },
  },
}
</script>
